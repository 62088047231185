<script setup lang="ts">
import { PropType } from 'vue'
import IconClose from '@/assets/icons/close.svg'
import IconLoading from '@/assets/icons/loading.svg'

const props = defineProps({
  color: {
    type: String as PropType<'yellow' | 'white' | 'black'>,
    default: 'yellow',
  },
  progress: {
    type: Number,
    default: 0,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  showCancelBtn: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: '45px',
  },
  barWidth: {
    type: Number,
    default: 1.2,
  },
})

const colors = {
  yellow: { barColor: '#fdbd00', pathColor: '#67676759' },
  white: { barColor: '#ffffffb3', pathColor: '#ffffff4d' },
  black: { barColor: '#3b3b3b', pathColor: '#ebebeb' },
}

const color = computed(() => colors[props.color])

const svgCircle = ref<SVGCircleElement>(null)
const totalLength = ref(0)

function setPercentage(percentage: number) {
  let per = totalLength.value - (percentage / 100) * totalLength.value

  if (per < 0) per = 0

  svgCircle.value.style.strokeDashoffset = per.toString()
}

onMounted(() => {
  if (!document.getElementById('progress-bar')) return
  svgCircle.value = document
    .getElementById('progress-bar')
    .querySelector('#bar')

  totalLength.value = Math.ceil(svgCircle.value.getTotalLength())
})

watch(
  () => props.progress,
  () => {
    setPercentage(props.progress)
  },
)
</script>

<template>
  <div class="relative">
    <div
      v-if="!showCancelBtn && progress"
      class="absolute left-1/2 top-1/2 z-10 w-fit -translate-x-1/2 -translate-y-1/2 transform cursor-pointer text-base"
    >
      {{ progress }}%
    </div>
    <IconClose
      v-if="showCancelBtn"
      class="absolute z-10 scale-[20%] cursor-pointer"
      :stroke="color.barColor"
      :fill="color.barColor"
      :width="size"
      :height="size"
    />
    <IconLoading
      v-if="isLoading && !progress"
      class="scale-[70%]"
      :width="size"
      :height="size"
      :stroke-width="barWidth"
      :color="color.barColor"
    />
    <div :class="progress ? '' : 'absolute opacity-0'">
      <svg
        id="progress-bar"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 10"
        :width="size"
        :height="size"
        :stroke-width="barWidth"
      >
        <circle cx="5" cy="5" r="3" :stroke="color.pathColor" />
        <circle id="bar" cx="5" cy="5" r="3" :stroke="color.barColor" />
      </svg>
    </div>
  </div>
</template>

<style scoped>
#progress-bar {
  transform: rotate(-90deg);
  fill: transparent;
  transition: stroke-dashoffset 0.3s;
}

#progress-bar #bar {
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
}
</style>
